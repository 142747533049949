import Footer from "./components/commons/footer";
import ProfileBanner from "./components/profile_banner/profile_banner";
import Agfinity from "./components/software_proj/agfinity";
import BanditBrigade from "./components/software_proj/bndt/bndt";
import KrungsriNimble from "./components/software_proj/krungsri_nimble";
import Tickbox from "./components/software_proj/tickbox";

function App() {
  return (
    <div className="flex flex-col items-center justify-center bg-[#F2F2F1] pb-10 md:px-80 md:py-10">
      <ProfileBanner />
      <div className="pt-20"></div>
      <BanditBrigade />
      <Agfinity />
      <Tickbox />
      <KrungsriNimble />
      <Footer />
    </div>
  );
}

export default App;
