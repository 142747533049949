import React from "react";
import SocialButton from "./social_button";

// Image imports
import ProfilePic from "../../assets/images/profile_pic.jpg";
import GithubLogo from "../../assets/icons/github.svg";
import GmailLogo from "../../assets/icons/gmail.svg";
import LinkedInLogo from "../../assets/icons/linkedin.svg";
import BannerImage from "../../assets/images/banner.jpg";
import ResumePDF from "../../assets/resume.pdf";

function ProfileBanner() {
  return (
    <div className="w-full flex-col justify-center md:w-screen lg:w-[960px]">
      <div className="relative">
        <div className="h-[230px] w-full overflow-clip bg-center object-center md:rounded-lg lg:w-[960px]">
          <img
            className="-translate-y-48 object-cover"
            src={BannerImage}
            alt="Banner"
          ></img>
          <div className="absolute -bottom-20 left-4 h-44 w-44 overflow-clip rounded-xl border-4 border-solid border-white md:left-10">
            <img className="scale-150" src={ProfilePic} alt="Profile"></img>
          </div>
        </div>
      </div>

      <div className="flex w-full items-end justify-end pr-4 pt-4 md:pr-8 lg:w-[960px]">
        <div className="flex flex-row justify-items-center space-x-1 md:space-x-2.5">
          <SocialButton
            link="https://www.linkedin.com/in/pattaramet/"
            icon={LinkedInLogo}
          />

          <SocialButton
            link="mailto:pattaramet.chai@gmail.com"
            icon={GmailLogo}
            showEmail={true}
          />
          <div
            id="tooltip-default"
            role="tooltip"
            class="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700"
          >
            Tooltip content
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
          <SocialButton link="https://github.com/poommm253" icon={GithubLogo} />

          <a
            href={ResumePDF}
            target="_blank"
            rel="noreferrer"
            class="group relative inline-flex h-10 w-28 items-center justify-start overflow-hidden rounded-xl bg-red-500 px-6 py-3 font-medium transition-all"
          >
            <span class="absolute right-0 top-0 inline-block h-4 w-4 rounded bg-red-700 transition-all duration-500 ease-in-out group-hover:-mr-4 group-hover:-mt-4">
              <span class="absolute right-0 top-0 h-5 w-5 -translate-y-1/2 translate-x-1/2 rotate-45 bg-white"></span>
            </span>
            <span class="absolute bottom-0 left-0 h-full w-full -translate-x-full translate-y-full rounded-2xl bg-red-600 transition-all delay-200 duration-500 ease-in-out group-hover:mb-12 group-hover:translate-x-0"></span>
            <span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">
              Resume
            </span>
          </a>
        </div>
      </div>

      <h2 className="pl-4 pt-10 text-2xl font-bold md:pl-10">
        Poom Chaisirinon
      </h2>
      <spacer />
      <h3 className="text-md pl-4 pt-3 md:px-10 md:text-lg">
        <strong className="font-semibold">
          Front-end Developer. Mobile Developer. UI/UX Designer.
        </strong>{" "}
        I am fueled by a passion for creating captivating and intuitive digital
        experiences that leave a lasting impression.
      </h3>
    </div>
  );
}

export default ProfileBanner;
