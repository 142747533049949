import React from "react";
import SocialButton from "../profile_banner/social_button";

// Image imports
import GithubLogo from "../../assets/icons/github.svg";
import GmailLogo from "../../assets/icons/gmail.svg";
import LinkedInLogo from "../../assets/icons/linkedin.svg";
import ResumePDF from "../../assets/resume.pdf";

function Footer() {
  return (
    <div className="flex w-screen flex-row items-center justify-between px-10 font-extralight md:w-screen lg:w-[990px]">
      <div className="text-xs font-extralight text-gray-300">
        Figma, Flutter, Github, Gmail, Golang, LinkedIn, Python, React, Tailwind
        icons by{" "}
        <a target="_blank" rel="noreferrer" href="https://icons8.com">
          Icons8
        </a>
      </div>
      <div className="flex flex-row space-x-4">
        <SocialButton
          link="https://www.linkedin.com/in/pattaramet/"
          icon={LinkedInLogo}
        />

        <SocialButton
          link="mailto:pattaramet.chai@gmail.com"
          icon={GmailLogo}
          showEmail={true}
        />
        <div
          id="tooltip-default"
          role="tooltip"
          class="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700"
        >
          Tooltip content
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <SocialButton link="https://github.com/poommm253" icon={GithubLogo} />

        <a
          href={ResumePDF}
          target="_blank"
          rel="noreferrer"
          class="group relative inline-flex h-10 w-28 items-center justify-start overflow-hidden rounded-xl bg-red-500 px-6 py-3 font-medium transition-all"
        >
          <span class="absolute right-0 top-0 inline-block h-4 w-4 rounded bg-red-700 transition-all duration-500 ease-in-out group-hover:-mr-4 group-hover:-mt-4">
            <span class="absolute right-0 top-0 h-5 w-5 -translate-y-1/2 translate-x-1/2 rotate-45 bg-white"></span>
          </span>
          <span class="absolute bottom-0 left-0 h-full w-full -translate-x-full translate-y-full rounded-2xl bg-red-600 transition-all delay-200 duration-500 ease-in-out group-hover:mb-12 group-hover:translate-x-0"></span>
          <span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">
            Resume
          </span>
        </a>
      </div>
    </div>
  );
}

export default Footer;
