export const tickboxData = {
  title: "Tickbox",
  description:
    "Making education easy for both students and professors. Our functions are designed to provide easy access to key course information without the need for tedious searches through varying syllabus structures. Tickbox allows students to instantly import their course assignments in a to-do list format along with a summarized course syllabus.",
  tags: ["Flutter", "Figma"],
};

export const agfinityData = {
  title: "Agfinity",
  description:
    "At Grain Discovery, I led the design and development of a mobile grain brokerage app from scratch. Research stakeholder requirements with client and provide weekly updates. Developed using MVVM architecture with l10n internationalization for an clean and maintainable codebase. Allows grain elevators and farmers to view trade histories, place bids, and calculate grain princing all in one app.",
  tags: ["Flutter", "Figma"],
};

export const krungsriNumbleData = {
  title: "Nimble Poll",
  description:
    "Designed and developed a solution for automated employee evaluation and daily check-in process to reduce the workload for the HR department at Krungsri. Implemented OAuth 2.0 via Okta as a single sign in solution. Integrate existing API services using GoLang.",
  tags: ["Flutter", "Figma", "Golang"],
};

export const banditBrigadeData = {
  title: "Bandit Brigade",
  descirption:
    "NFT concept website. Join the Bandit Brigade in a distopian setting as they navigate through the complex and dangerous world riddened with the mysterious element - Lunarite. Developed and deployed an immersive website with scroll-based animation and a Discord bot that can support real-time multiplayer RPG.",
  tags: ["ReactJS", "Tailwind", "Python"],
};
