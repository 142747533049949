import React, { useEffect, useState } from "react";
import ProjectTitle from "../commons/project_title";
import { tickboxData } from "./software_proj_data";

// Image imports
import TickboxLogo from "../../assets/images/tickbox/logo.svg";
import TickboxHeroImage from "../../assets/images/tickbox/hero_image.png";
import TickboxHeroImageSmall from "../../assets/images/tickbox/hero_image_small.png";
import TickboxSyllabus from "../../assets/images/tickbox/syllabus.png";
import TickboxStoreListing from "../../assets/images/tickbox/store_listing.png";
import TickboxTodo from "../../assets/images/tickbox/todo_list.png";
import { Fade } from "react-reveal";

function Tickbox() {
  return (
    <div className="project-block">
      <ProjectTitle
        title={tickboxData.title}
        description={tickboxData.description}
        tags={tickboxData.tags}
      />
      <TickboxGrid />
    </div>
  );
}

function TickboxGrid() {
  const [isDesktop, setIsDesktop] = useState(window.innterWidth >= 1024);

  const updateViewportSize = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", updateViewportSize);
    return () => window.removeEventListener("resize", updateViewportSize);
  });

  return (
    <Fade cascade bottom>
      <div className="project-grid">
        <div className="bg-white md:col-span-3 lg:col-span-2">
          <div className="flex h-full flex-col items-center justify-end">
            <h1 className="w-3/5 py-8 text-center text-3xl font-bold">
              Planning how to plan is just ridiculous.
            </h1>
            {isDesktop ? (
              <img
                className=" object-fill p-8 pt-0"
                src={TickboxHeroImage}
                alt="Tickbox Screenshot Showcase"
              ></img>
            ) : (
              <img
                className="pb-8"
                src={TickboxHeroImageSmall}
                alt="Tickbox Screenshot Showcase"
              ></img>
            )}
          </div>
        </div>

        <div className="flex h-full flex-col items-center justify-between bg-white pb-8 pt-4 md:col-span-3 lg:col-span-1">
          <img
            className="-translate-x-4"
            src={TickboxSyllabus}
            alt="Tickbox Syllabus"
          ></img>
          <h1 className="w-3/5 -translate-y-4 text-center text-3xl font-bold">
            Syllabus Summary
          </h1>
          <text className="w-3/4 text-center text-sm font-light">
            Easy-access to key syllabus info (marks break down, contact info,
            office hours, and more) in one concise course summary screen.
          </text>
        </div>

        <div className="flex w-full items-center justify-center bg-[#E4E4F3] py-10 md:col-span-3 md:h-[400px] md:w-full lg:col-span-1">
          <img
            className="h-20 md:h-max"
            src={TickboxLogo}
            alt="Tickbox Logo"
          ></img>
        </div>

        <div className="bg-white md:col-span-3 lg:col-span-2">
          <div className="flex h-full flex-row items-center space-x-4 pl-10">
            <div className="flex flex-col">
              <h1 className="text-3xl font-bold">Courses as to-do list</h1>
              <br></br>
              <text className="text-sm font-light">
                Browse or scan QR codes from our collection of courses to import
                their assignments in a to-do list format automatically.
              </text>
            </div>
            <img
              className="w-[360px] translate-x-3 object-cover"
              src={TickboxTodo}
              alt="Tickbox Todo UI"
            ></img>
          </div>
        </div>

        <div className="flex items-center justify-center bg-white md:col-span-3">
          <img
            className="object-cover p-8"
            src={TickboxStoreListing}
            alt="Tickbox Banner"
          ></img>
        </div>
      </div>
    </Fade>
  );
}

export default Tickbox;
