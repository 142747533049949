import React from "react";
import ProjectTitle from "../../commons/project_title";
import Sneakpeek from "./sneakpeek";
import Fade from "react-reveal/Fade";
import { banditBrigadeData } from "../software_proj_data";

// Image imports
import Logo from "../../../assets/images/bndt/logo_white.png";
import WorkshopImage from "../../../assets/images/bndt/workshop.jpg";
import GoldCard from "../../../assets/images/bndt/gold_card.jpg";
import DiscordGame from "../../../assets/images/bndt/discord_game.png";
import CanisterVideo from "../../../assets/images/bndt/canister.mp4";
import CanisterPoster from "../../../assets/images/bndt/canister_poster_img.png";

function BanditBrigade() {
  return (
    <div className="project-block">
      <ProjectTitle
        title={banditBrigadeData.title}
        description={banditBrigadeData.descirption}
        tags={banditBrigadeData.tags}
        learnMore={true}
        learnMoreLink={"https://bandit-brigade.pages.dev/"}
      />
      <div
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          window.open("https://bandit-brigade.pages.dev/");
        }}
      >
        <BanditBrigadeGrid />
      </div>
    </div>
  );
}

function BanditBrigadeGrid() {
  return (
    <Fade cascade bottom>
      <div className="project-grid">
        <div className="flex overflow-clip lg:col-span-2 lg:row-span-2">
          <video autoPlay muted={true} loop poster={CanisterPoster}>
            <source src={CanisterVideo}></source>
          </video>
        </div>

        <div className="col-span-1 flex overflow-clip">
          <img
            className="h-full object-fill"
            src={GoldCard}
            alt="gold card"
          ></img>
        </div>

        <div className="col-span-1 bg-black">
          <img src={Logo} alt="Logo"></img>
        </div>

        <div className="col-span-1 flex flex-col overflow-clip bg-black lg:p-5">
          <img
            className="p-10 lg:p-0 lg:pt-2"
            src={DiscordGame}
            alt="Discord RPG UI Demo"
          ></img>
          <h2 className="py-6 text-center text-sm font-bold text-white">
            Multiplayer RPG game on Discord made with Python
          </h2>
          <text className="pb-1 text-center text-sm font-light text-white">
            Select from 5 different roles, form teams, collect tools, and join
            the expedition to mine Lunarites.
          </text>
        </div>

        <div className="flex overflow-clip lg:col-span-2">
          <img
            className="object-cover"
            src={WorkshopImage}
            alt="workshop bndt store"
          ></img>
        </div>

        <div className="col-span-1 h-[320px] overflow-clip lg:col-span-3">
          <div className="-translate-y-10">
            <Sneakpeek />
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default BanditBrigade;
