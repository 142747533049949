import React from "react";

// Image imports
import FigmaIcon from "../../assets/icons/figma.svg";
import FlutterIcon from "../../assets/icons/flutter.svg";
import GolangIcon from "../../assets/icons/golang.svg";
import PythonIcon from "../../assets/icons/python.svg";
import ReactIcon from "../../assets/icons/react.svg";
import TailwindIcon from "../../assets/icons/tailwind-css.svg";

function Tags({ title }) {
  var icon;

  switch (title) {
    case "Figma":
      icon = FigmaIcon;
      break;
    case "Flutter":
      icon = FlutterIcon;
      break;
    case "Golang":
      icon = GolangIcon;
      break;
    case "Python":
      icon = PythonIcon;
      break;
    case "ReactJS":
      icon = ReactIcon;
      break;
    case "Tailwind":
      icon = TailwindIcon;
      break;
    default:
      icon = FigmaIcon;
  }

  return (
    <div className="mx-4 flex flex-row rounded-full bg-white px-4 py-2">
      <div className="h-5 w-5 rounded-full md:mr-2 ">
        <img className="h-5 w-5" src={icon} alt=""></img>
      </div>
      <text className="hidden text-sm md:block">{title}</text>
    </div>
  );
}

export default Tags;
