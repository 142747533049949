import React from "react";
import Tags from "./tags";

function ProjectTitle({ title, description, tags, learnMore, learnMoreLink }) {
  return (
    <div className="flex flex-col items-center p-10">
      <h2 className="pb-8 text-center text-5xl font-bold">{title}</h2>
      <text className="pb-8 text-center">
        {description}{" "}
        {learnMore && (
          <button
            className="cursor-pointer text-red-500"
            onClick={(e) => {
              e.preventDefault();
              window.open(learnMoreLink);
            }}
          >
            <u>View Project</u>
          </button>
        )}
      </text>
      <div className="flex flex-row">
        {tags.map((tag) => (
          <Tags title={tag} />
        ))}
      </div>
    </div>
  );
}

export default ProjectTitle;
