import React from "react";
import ProjectTitle from "../commons/project_title";
import { agfinityData } from "./software_proj_data";

import AgfinityLogo from "../../assets/images/agfinity/agfinity_logo.png";
import CommodityFilter from "../../assets/images/agfinity/filter.png";
import TradeHistory from "../../assets/images/agfinity/trade_history.png";
import GrainCalculator from "../../assets/images/agfinity/grain_calculator.png";
import HeroImage from "../../assets/images/agfinity/hero_image.png";
import { Fade } from "react-reveal";

function Agfinity() {
  return (
    <div className="project-block">
      <ProjectTitle
        title={agfinityData.title}
        description={agfinityData.description}
        tags={agfinityData.tags}
        learnMore={true}
        learnMoreLink={"https://qrco.de/Agfinity"}
      />

      <div
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          window.open("https://qrco.de/Agfinity");
        }}
      >
        <AgfinityGrid />
      </div>
    </div>
  );
}

function AgfinityGrid() {
  return (
    <Fade bottom cascade>
      <div className="project-grid">
        <div className="flex items-center justify-center bg-white lg:col-span-2">
          <img src={HeroImage} alt="Agfinity Screenshots"></img>
        </div>
        <div className="flex items-center justify-center bg-[#1F1F1F] py-10">
          <img src={AgfinityLogo} alt="Agfinity Logo"></img>
        </div>
        <div className="flex flex-col items-center justify-end bg-white md:min-h-[486px]">
          <h2 className="pt-10 text-center text-3xl font-bold">Commodity</h2>
          <h2 className="text-center text-3xl font-bold">Filtering.</h2>
          <br />
          <br />
          <img
            src={CommodityFilter}
            alt="Agfinity Screenshot Showcasing Commodity Filter Feature"
          ></img>
        </div>
        <div className="flex flex-col items-center justify-end bg-white">
          <h2 className="pt-10 text-center text-3xl font-bold">Trade</h2>
          <h2 className="text-center text-3xl font-bold">History.</h2>
          <br />
          <br />
          <img
            src={TradeHistory}
            alt="Agfinity Screenshot Showcasing Trade History Feature"
          ></img>
        </div>
        <div className="flex flex-col items-center justify-end bg-white">
          <h2 className="pt-10 text-center text-3xl font-bold">Grain</h2>
          <h2 className="text-center text-3xl font-bold">Calculator.</h2>
          <br />
          <br />
          <img
            src={GrainCalculator}
            alt="Agfinity Screenshot Showcasing Grain Calculator Feature"
          ></img>
        </div>
      </div>
    </Fade>
  );
}

export default Agfinity;
