import React from "react";
import ProjectTitle from "../commons/project_title";

// Image imports
import HeroImage from "../../assets/images/krungsri_nimble/hero_image.png";
import { krungsriNumbleData } from "./software_proj_data";
import { Fade } from "react-reveal";

function KrungsriNimble() {
  return (
    <div className="project-block">
      <ProjectTitle
        title={krungsriNumbleData.title}
        description={krungsriNumbleData.description}
        tags={krungsriNumbleData.tags}
      />
      <KrungsriNimbleGrid />
    </div>
  );
}

function KrungsriNimbleGrid() {
  return (
    <Fade bottom>
      <div className="project-grid md:grid-cols-1">
        <div className="flex justify-center rounded-3xl bg-white">
          <img src={HeroImage} alt="Nimble Poll Screenshot"></img>
        </div>
      </div>
    </Fade>
  );
}

export default KrungsriNimble;
