import React from "react";

import Sneakpeek1 from "../../../assets/images/bndt/sneakpeeks/1.jpg";
import Sneakpeek2 from "../../../assets/images/bndt/sneakpeeks/2.jpg";
import Sneakpeek3 from "../../../assets/images/bndt/sneakpeeks/3.jpg";
import Sneakpeek4 from "../../../assets/images/bndt/sneakpeeks/4.jpg";
import Sneakpeek5 from "../../../assets/images/bndt/sneakpeeks/5.jpg";
import Sneakpeek6 from "../../../assets/images/bndt/sneakpeeks/6.jpg";
import Sneakpeek7 from "../../../assets/images/bndt/sneakpeeks/7.jpg";
import Sneakpeek8 from "../../../assets/images/bndt/sneakpeeks/8.jpg";

function Sneakpeek() {
  return (
    <div className="flex bg-[#F2F2F5] p-0 pb-12">
      <div className="relative h-[373px] w-full overflow-x-hidden overflow-y-hidden">
        <div className="animate-sneakpeek absolute left-0 flex w-full">
          <div className="flex h-[373px] min-w-[3176px] space-x-6">
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek1}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek2}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek3}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek4}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek5}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek6}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek7}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek8}
              alt="BNDT Sneakpeek"
            ></img>
          </div>

          <div className="flex h-[373px] min-w-[3176px] space-x-6">
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek1}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek2}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek3}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek4}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek5}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek6}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek7}
              alt="BNDT Sneakpeek"
            ></img>
            <img
              className="h-[373px] w-[373px]"
              src={Sneakpeek8}
              alt="BNDT Sneakpeek"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sneakpeek;
