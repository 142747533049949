import React from "react";

function SocialButton({ icon, link, showEmail }) {
  return (
    <button
      href={link}
      type="button"
      onClick={(e) => {
        e.preventDefault();
        window.open(link);
      }}
      className="whitespace-no-wrap group relative inline-flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-white p-2 text-base font-medium leading-6 text-gray-600 shadow-sm hover:bg-gray-50 focus:shadow-none focus:outline-none"
    >
      <img src={icon} alt="Social Media Icon"></img>
      <span
        class="absolute left-1/2 m-4 mx-auto -translate-x-1/2 translate-y-10 rounded-md bg-gray-400 px-1 
    text-sm text-white opacity-0 transition-opacity group-hover:opacity-100"
      >
        {link.replace("mailto:", "")}
      </span>
    </button>
  );
}

export default SocialButton;
